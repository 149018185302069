<template>
  <div class="full-card has-table">
    <div class="handle-area">
      <a-button type="primary" icon="plus" @click="openCreate('create')" v-has-permission="['addAccessories']">新增配件/耗材</a-button>
      <div class="filter margin-l20" @click="filterShow = true"></div>
    </div>
    <a-card>
      <a-table
          :columns="columns"
          :data-source="list"
          :loading="tableLoading"
          :pagination="pagination"
          rowKey="id"
          bordered
          @change="pageChange"
          :scroll="{ y: 'calc(100% - 55px)'}"
      >
        <template slot="id" slot-scope="text, record">
          <img src="@/assets/image/common/edit.png" alt="" @click="openCreate('edit', record)" v-has-permission="['editAccessories']">
          <img src="@/assets/image/common/link.png" alt="" class="margin-l20" @click="openLink(record)" v-has-permission="['bindAccessoriesParam']">
          <img src="@/assets/image/common/delete.png" alt="" class="margin-l20" @click="delItem(record)" v-has-permission="['deleteAccessories']">
        </template>
      </a-table>
    </a-card>
    <a-drawer
        title="过滤器"
        placement="right"
        :visible="filterShow"
        @close="filterShow = false"
        width="500px"
        class="drawer"
    >
      <div class="drawer-cot">
        <a-form-model labelAlign="right" :label-col="{ span: 6 }" :wrapper-col="{ span: 18 }" :model="filterForm" class="drawer-form">
          <a-form-model-item :wrapperCol="{ span: 24 }">
            <a-input-search placeholder="配件/耗材名称或ID" v-model="filterForm.name" @search="onFilter" />
          </a-form-model-item>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="search" @click="onFilter">筛选</a-button>
          <a-button style="margin-left: 10px;" icon="reload" @click="onReset">重置</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        :title="`${ form.id ? '编辑' : '新增'}配件/耗材` "
        placement="right"
        :visible="createShow"
        @close="closeCreate"
        width="500px"
        class="drawer"
        v-if="createShow"
    >
      <div class="drawer-cot">
        <a-form-model
            ref="form"
            labelAlign="right"
            :label-col="{ span: 6 }"
            :wrapper-col="{ span: 18 }"
            :model="form"
            :rules="rules"
            class="drawer-form"
        >
          <a-row>
            <a-form-model-item label="配件/耗材名称" prop="productAccessoriesName">
              <a-input placeholder="请输入配件/耗材名称" v-model="form.productAccessoriesName" />
            </a-form-model-item>
            <a-form-model-item label="配件/耗材ID" prop="productAccessoriesCode">
              <a-input placeholder="请输入配件/耗材ID" v-model="form.productAccessoriesCode" />
            </a-form-model-item>
            <a-form-model-item label="备注" prop="remark">
              <a-textarea placeholder="请输入备注" v-model="form.remark" :auto-size="{ minRows: 3, maxRows: 5 }" />
            </a-form-model-item>
          </a-row>
        </a-form-model>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="onSubmit">确定</a-button>
          <a-button icon="close" class="margin-l20" @click="closeCreate">取消</a-button>
        </div>
      </div>
    </a-drawer>
    <a-drawer
        title="关联配件/耗材参数"
        placement="right"
        :visible="linkShow"
        @close="linkShow = false"
        width="500px"
        class="drawer"
        v-if="linkShow"
    >
      <div class="drawer-cot">
        <div class="drawer-form">
          <div class="margin-t10 display-f justify-center">
            <a-input-search placeholder="配件/耗材参数名称或ID" v-model="paramsForm.name" @search="getAccessoriesParam" style="width: 400px" />
          </div>
          <a-table
              :row-selection="rowSelection"
              :columns="paramColumns"
              :data-source="accessoriesParamList"
              :loading="tableLoading"
              :pagination="false"
              rowKey="id"
              bordered
              class="margin-t30"
          >
            <template slot="id" slot-scope="text, record">
              <a-input placeholder="请输入数值" v-model="record.staticParameterValue"/>
            </template>
          </a-table>
        </div>
        <div class="drawer-handle">
          <a-button type="primary" icon="check" @click="linkSubmit">关联</a-button>
          <a-button class="margin-l20" icon="close" @click="linkShow = false">取消</a-button>
        </div>
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  getAccessoriesList,
  createAccessories,
  deleteAccessories,
  bindaAndcParam,
  bindAAndCParam
} from '../../api/product/accessories'
import { getAccessoriesParamList, getAccessoriesParamListNew } from '../../api/product/accessoriesParam'
export default {
  name: 'accessoriesAndConsumablesList',
  data () {
    return {
      columns: [
        {
          title: '配件/耗材名称',
          dataIndex: 'productAccessoriesName'
        },
        {
          title: '配件/耗材ID',
          dataIndex: 'productAccessoriesCode'
        },
        {
          title: '最近修改时间',
          dataIndex: 'updateTime'
        },
        {
          title: '备注',
          dataIndex: 'remark'
        },
        {
          title: '操作',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        }
      ],
      list: [],
      total: 0,
      createShow: false,
      filterShow: false,
      linkShow: false,
      filterForm: {
        pageNum: 1,
        pageSize: 10
      },
      form: {},
      page: 1,
      roleList: [],
      tableLoading: true,
      pagination: {
        total: 0,
        pageSize: 10
      },
      staffShow: false,
      customerShow: false,
      rules: {
        productAccessoriesName: [
          { required: true, message: '请输入配件/耗材名称', trigger: 'blur' }
        ],
        productAccessoriesCode: [
          { required: true, message: '请输入配件/耗材ID', trigger: 'blur' }
        ]
      },
      accessoriesParamList: [],
      paramsForm: {},
      paramColumns: [
        {
          title: '配件/耗材参数名称',
          dataIndex: 'accessoriesParametersName'
        },
        {
          title: '数据单位',
          dataIndex: 'dataUnit'
        },
        {
          title: '静态参数赋值',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        },
      ],
      bindParamList: [],
      selectedId: '',
      paramSelectedRowKeys: [],
    }
  },
  mounted () {
    this.getList()
  },
  methods: {
    getList () {
      return new Promise( (resolve) => {
        this.tableLoading = true
        getAccessoriesList(this.filterForm).then(res => {
          this.list = res.data.list
          this.pagination.total = res.data.total
          this.tableLoading = false
          resolve(res.data)
        })
      })
    },
    pageChange (pages) {
      this.filterForm.pageNum = pages.current
      this.getList()
    },
    openCreate (type, item) {
      if (item) this.form = JSON.parse(JSON.stringify(item))
      this.createShow = true
    },
    closeCreate () {
      this.form = {}
      this.createShow = false
    },
    delItem (item) {
      this.$confirm({
        title: '提示',
        content: '确认删除这条数据？',
        centered: true,
        onOk: () => {
          deleteAccessories({
            ids: [item.id]
          }).then(() => {
            this.$message.success('删除成功！')
            this.getList()
          })
        }
      })
    },
    onSubmit () {
      this.$refs.form.validate(valid => {
        if (valid) {
          createAccessories(this.form).then(() => {
            this.$message.success('操作成功！')
            this.getList()
            this.closeCreate()
          })
        }
      })
    },
    onFilter () {
      this.filterForm.pageNum = 1
      this.getList().then(() => {
        this.filterShow = false
      })
    },
    onReset () {
      this.filterForm = {
        pageNum: 1,
        pageSize: 10
      }
      this.getList()
    },
    openLink (item) {
      this.selectedId = item.id
      this.getAccessoriesParam()
    },
    getAccessoriesParam () {
      getAccessoriesParamListNew({
        pageNum: 1,
        pageSize: 10000,
        accessoriesId: this.selectedId,
        name: this.paramsForm.name
      }).then(res => {
        this.accessoriesParamList = res.data.list
        let arr = []
        res.data.list.forEach(item => {
          if (item.binded) {
            arr.push(item.id)
          }
        })
        this.paramSelectedRowKeys = arr
        this.linkShow = true
      })
    },
    linkSubmit () {
      bindAAndCParam({
        accessoriesId: this.selectedId,
        bindParameterInfos: this.bindParamList
      }).then(() => {
        this.$message.success('关联成功！')
        this.linkShow = false
      })
    },
  },
  computed: {
    ...mapState({
      // 动态主路由
      authority: state => state.user.authority,
    }),
    rowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          selectedRows.forEach(item => {
            item.parameterId = item.id
          })
          this.bindParamList = selectedRows
          this.paramSelectedRowKeys = selectedRowKeys
        },
        selectedRowKeys: this.paramSelectedRowKeys
      };
    },
  },
}
</script>

<style scoped>

</style>
